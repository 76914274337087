// extracted by mini-css-extract-plugin
export var atRipple = "ClipsIndex-module--at-ripple--cWahN";
export var background = "ClipsIndex-module--background--fQ0Dy";
export var clip = "ClipsIndex-module--clip--2kBqe";
export var clipTitle = "ClipsIndex-module--clipTitle--JKaG0";
export var clips = "ClipsIndex-module--clips--eBkhv";
export var isActive = "ClipsIndex-module--isActive--RARl4";
export var ripple = "ClipsIndex-module--ripple--X0daC";
export var soundDesc = "ClipsIndex-module--soundDesc--zPDoZ";
export var soundProgress = "ClipsIndex-module--soundProgress--eA++S";
export var videoWrapper = "ClipsIndex-module--videoWrapper--y4ySD";