import cn from 'classnames';
import Wrapper from 'components/Wrapper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import ProgressRing from '../../CavesIndexTpl/ProgressRing';
import * as styles from './ClipsIndex.module.scss';

class ClipsIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			soundUrl: '',
			playing: false,
			played: 0,
			activeSoundIndex: null,
			videoUrl: '',
		}
	}

	handlePlayPauseClip(index) {
		const { lists } = this.props;
		const soundUrl = lists[index].sound;
		const videoUrl = lists[index].video;
		this.setState((state) => {
			if (state.soundUrl === soundUrl) {
				return {
					soundUrl,
					videoUrl,
					playing: !this.state.playing,
					activeSoundIndex: index,
				}
			} else {
				return {
					soundUrl,
					videoUrl,
					playing: true,
					activeSoundIndex: index,
					played: 0,
				}
			}
		});
	}

	handleProgress = state => {
    if (!this.state.seeking) {
      this.setState(state);
    }
	}

	handleSeekChange = e => this.setState({ played: parseFloat(e.target.value) });

	handlePauseClip = () => this.setState({ playing: false });

	onMouseMove = e => {
		const cursor = document.querySelector('#cursor');
		cursor.style.left = `${e.pageX}px`;
		cursor.style.top = `${e.pageY}px`;
		cursor.style.display = 'block';
	}
	onMouseLeave = () => {
		const cursor = document.querySelector('#cursor');
		cursor.style.display = 'none';
	}
	onMouseEnter = () => {
		const cursor = document.querySelector('#cursor');
		cursor.style.display = 'block';
	}

	render() {
		const { lists, className } = this.props;
		const { soundUrl, playing, played, activeSoundIndex, videoUrl } = this.state;

		return (
			<div className={(className)}>
				<div className={cn(styles.videoWrapper, { [styles.isActive]: playing })}>
					<div className="btnClose btnFixed" onClick={this.handlePauseClip}></div>
					<ReactPlayer url={videoUrl} playing={playing} muted={true} loop={true} width="100%" height="auto" />
				</div>
				<Wrapper size="Xl">
					<ul className={styles.clips}>
					{ lists.map(({ heading, description }, index) => (
						<li
							key={index}
							onClick={() => this.handlePlayPauseClip(index)}
							className={cn(styles.clip, {
								[styles.isActive]: (index === activeSoundIndex)
							})}
							onMouseMove={this.onMouseMove}
							onMouseLeave={this.onMouseLeave}
							onMouseEnter={this.onMouseEnter}
						>
							<div className={styles.background}>
							<h2 className={styles.clipTitle}>{heading}</h2>
							</div>
						
							<div className={cn(styles.soundProgress, {
								[styles.isActive]: (index === activeSoundIndex)
							})}>
								<ProgressRing progress={played * 100} radius={70} />
							</div>
							<span className={cn(styles.ripple, {
								[styles.isActive]: (index === activeSoundIndex && playing)
							})} />
							<h3 className={cn(styles.soundDesc, {
								[styles.isActive]: (index === activeSoundIndex && playing)
							})}>
								<span>{description}</span>
							</h3>
						</li>
					)) }
					</ul>
				</Wrapper>
				<div>
					<ReactPlayer
						url={soundUrl}
						playing={playing}
						controls={false}
						loop={true}
						onProgress={this.handleProgress}
						volume={1}
						progressInterval={200}
						width={0}
						height={0}
					/>
				</div>
			</div>
		)
	}
}

ClipsIndex.propTypes = {
	lists: PropTypes.array.isRequired,
	className: PropTypes.string,
}

export default ClipsIndex;