import Metadata from 'components/Metadata';
import PropTypes from 'prop-types';
import React from 'react';
import CaveBanner from '../CavesIndexTpl/CaveBanner';
import CaveFooter from '../CavesIndexTpl/CaveFooter';
import ClipsIndex from './ClipsIndex';
import * as styles from './KooSanIndexTpl.module.scss';

const KooSanIndexTpl = ({ data }) => {
  const {
    kooSanPage: {
      title,
      meta: { description },
      heading,
      description: desc,
      image,
      lists,
      footer
    },
  } = data;
  return (
    <div className={styles.wrapper}>
      <Metadata title={title} description={description} />
      <CaveBanner desc={desc} image={image} heading={heading} className={styles.banner} />
      <ClipsIndex lists={lists} className={styles.clips} />
      <CaveFooter data={footer} className={styles.footer} />
    </div>
  );
};

KooSanIndexTpl.propTypes = {
  data: PropTypes.object,
};

export default KooSanIndexTpl;
