import Layout from 'components/Layout';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import KooSanIndexTpl from 'templates/Caves/KooSanIndexTpl';

const Tpl = props => (
  <Layout location={props.location}>
    <KooSanIndexTpl {...props} />
  </Layout>
);
const KooSanIndex = props => (
  <StaticQuery
    query={graphql`
      query KooSanQuery {
        kooSanPage: kooSanJson {
          title
          meta {
            description
          }
          heading
          description
          image {
            publicURL
          }
          mainSound
          lists {
            heading
            sound
            video
            description
          }
          footer {
            bgImage {
              publicURL
            }
            left {
              heading
              icon {
                publicURL
              }
            }
            right {
              heading
              image {
                publicURL
              }
              icon {
                publicURL
              }
            }
            goCaveLink
          }
        }
      }
    `}
    render={data => <Tpl data={data} {...props} />}
  />
);
Tpl.propTypes = {
  location: PropTypes.object,
};

export default KooSanIndex;